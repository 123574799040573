import {
  useFirebaseAuthentication,
  getOrCreateIdentity,
  Identity_SubscribeToChanges,
  parseJwt,
} from '@rabbit/data/portal';
import {
  DTRepairer_Private,
  DTRetailer_Private,
  Permissions,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { SagePersonaChecker } from './SagePersonaChecker';
import {
  Identity_EnsureIdentityExistsForAuthLikeObject,
  Sage_Identity_CreatePersonasForIdentity,
} from '@rabbit/bizproc/client';
import { SageAuthFormValuesShape } from '../components/templates/AuthForm/AuthForm';
import { init } from '@amplitude/analytics-browser';
import { useAppInfo } from '../utils/helpers';
import { useSageAPI } from '@rabbit/bizproc/react';
import BaseConfig from '@rabbit/config/base';
import { ExtendedDecodedIdToken } from '@rabbit/bizproc/server-logic';
import { ConfigContext } from '@rabbit/config/context';
import { useTranslation } from 'react-i18next';
import { Permissions2string } from '@rabbit/bizproc/core';
// TODO: ADD A PROPER TYPE FOR USER!
// TODO: Make this better equipped to handle multiple personas, instead of 2 state per persona - DC

export interface UserInterface {
  user: any;
  handleSignout: () => void;
  isLoading: boolean;
  initiated: boolean;
  repairerPersonaData: DTRepairer_Private | null;
  repairerPersonaId: string | null;
  retailerPersonaData: DTRetailer_Private | null;
  retailerPersonaId: string | null;
  accessToken: ExtendedDecodedIdToken | null;
  createSageRepairerUser: (
    formValues: SageAuthFormValuesShape
  ) => Promise<void>;
  sageSignIn: (values: SageAuthFormValuesShape) => Promise<void>;
  userConfig: typeof BaseConfig;
}

type UserProviderWrapperProps = {
  children: React.ReactNode;
};

const UserContext = createContext<UserInterface | null>(null);

const UserProviderWrapper = ({ children }: UserProviderWrapperProps) => {
  const { refreshClaimsForIdentity } = useSageAPI();
  const { t } = useTranslation();
  const { config, setConfig } = useContext(ConfigContext);
  const appInfo = useAppInfo();

  const {
    user: authUser,
    fbAuthCurrentUser,
    signOut,
    createWithEmail,
    signInWithEmail,
    setAuthPersistence,
  } = useFirebaseAuthentication();
  const [initiated, setInitiated] = useState(false);
  const [isLoading] = useState(false);
  const [isAuthAttempt, setIsAuthAttempt] = useState(false);
  const [accessToken, setAccessToken] = useState<ExtendedDecodedIdToken | null>(
    null
  );
  const [user, setUser] = useState<any>();
  const [repairerPersonaData, setRepairerPersonaData] =
    useState<DTRepairer_Private | null>(null);
  const [repairerPersonaId, setRepairerPersonaId] = useState<string | null>(
    null
  );
  const [retailerPersonaData, setRetailerPersonaData] =
    useState<DTRetailer_Private | null>(null);
  const [retailerPersonaId, setRetailerPersonaId] = useState<string | null>(
    null
  );
  const [userConfig, setUserConfig] = useState<typeof BaseConfig>({} as any);

  useEffect(() => {
    if (fbAuthCurrentUser) {
      setUser(fbAuthCurrentUser);
      setAccessToken(parseJwt((fbAuthCurrentUser as any).accessToken || ''));
    }
  }, [fbAuthCurrentUser]);

  // Create user with repairer persona
  const createSageRepairerUser = useCallback(
    async (values: SageAuthFormValuesShape) => {
      setIsAuthAttempt(true);
      try {
        const res = await createWithEmail(values.email, values.password);
        if (res?.user.uid)
          await CreatePersonasForNewSageUser(res?.user, values);
        values.rememberMe
          ? await setAuthPersistence('local')
          : await setAuthPersistence('session');

        await getOrCreateIdentity();
        setUser(res.user);
      } finally {
        setIsAuthAttempt(false);
      }
    },
    [createWithEmail, setAuthPersistence]
  );

  const sageSignIn = useCallback(
    async (values: SageAuthFormValuesShape) => {
      setIsAuthAttempt(true);
      try {
        values.rememberMe
          ? await setAuthPersistence('local')
          : await setAuthPersistence('session');
        const res = await signInWithEmail(values.email, values.password);

        if (res?.user?.uid) {
          void refreshClaimsForIdentity({ uid: res.user.uid });
        }
        await getOrCreateIdentity();
        setUser(res.user);
      } finally {
        setIsAuthAttempt(false);
      }
    },
    [setAuthPersistence, signInWithEmail]
  );

  useEffect(() => {
    const updateIdentifiers = async () => {
      if (
        !authUser.isLoading &&
        authUser.isSuccess &&
        authUser.data?.uid &&
        !isAuthAttempt
      ) {
        await getOrCreateIdentity();
        setUser(authUser.data);
      }

      if (!authUser.isLoading && authUser.data == null) {
        setUser(null);
        setRepairerPersonaData(null);
        setRepairerPersonaId(null);
        setRetailerPersonaData(null);
        setRetailerPersonaId(null);
      }
    };

    const initAmplitude = (uuid: string) => {
      init(appInfo.amplitude_key, uuid, {
        instanceName: appInfo.environment ?? '-',
        defaultTracking: {
          pageViews: {
            trackHistoryChanges: 'all',
          },
        },
      });
    };

    void updateIdentifiers();
    if (authUser.data?.uid) void initAmplitude(authUser.data?.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.isLoading, authUser.data?.uid]);

  useEffect(() => {
    if (!authUser.isLoading) setInitiated(true);
  }, [authUser.isLoading]);

  useEffect(() => {
    if (user?.accessToken) {
      const parsedJWT = parseJwt(user?.accessToken);
      console.debug(parsedJWT, 'parsedJWT');
      // TODO: Remove at the soonest
      // Updating config should never happen on FE

      const permissionsFromRootWarrantor =
        parsedJWT[
          `${
            PersonaTypeSingleLetter.Warrantor +
            PersonaIdTypeSplitter +
            t('tenantLink')
          }`
        ];

      const permissionsFromRootRepairer =
        parsedJWT[
          PersonaTypeSingleLetter.Repairer +
            PersonaIdTypeSplitter +
            t('tenantLink')
        ];

      //aka is the user a tenant
      if (
        permissionsFromRootWarrantor?.includes(
          Permissions2string([Permissions.Owner])
        )
      ) {
        if (config.NAVIGATION.RESTRICTED) {
          config.NAVIGATION.RESTRICTED_PATHS =
            config.NAVIGATION.RESTRICTED_USER_PATHS.Warrantor;
        }
      }

      // aka is the user a partner
      if (
        permissionsFromRootRepairer &&
        !permissionsFromRootRepairer?.includes(
          Permissions2string([Permissions.Owner])
        )
      ) {
        if (config.NAVIGATION.RESTRICTED) {
          config.NAVIGATION.RESTRICTED_PATHS =
            config.NAVIGATION.RESTRICTED_USER_PATHS.ExternalRepairer;
        }
        if (config.NAVIGATION.SAGE.DEFAULT_PARTNER_PATH) {
          config.NAVIGATION.SAGE.DEFAULT_PATH =
            config.NAVIGATION.SAGE.DEFAULT_PARTNER_PATH;
        }
      }

      setAccessToken(parsedJWT);
    }
  }, [user?.accessToken]);

  const handleSignout = useCallback(() => {
    signOut();
    setUser(null);
    setRepairerPersonaData(null);
    setRepairerPersonaId(null);
    setRetailerPersonaData(null);
    setRetailerPersonaId(null);
  }, [signOut]);

  const contextValues = useMemo(
    () => ({
      user,
      isLoading,
      handleSignout,
      initiated,
      repairerPersonaData,
      repairerPersonaId,
      retailerPersonaData,
      retailerPersonaId,
      createSageRepairerUser,
      sageSignIn,
      accessToken,
      userConfig,
    }),
    [
      user,
      handleSignout,
      isLoading,
      initiated,
      repairerPersonaData,
      repairerPersonaId,
      retailerPersonaData,
      retailerPersonaId,
      createSageRepairerUser,
      sageSignIn,
      accessToken,
      userConfig,
    ]
  );

  // //TRACK AMPLITUDE EVENT
  // const trackEvent = () => {
  //   track('Test amplitude Sage dev data connection');
  // };

  return (
    <UserContext.Provider value={contextValues}>
      {user && (
        <SagePersonaChecker
          setRepairerPersonaData={setRepairerPersonaData}
          setRepairerPersonaId={setRepairerPersonaId}
          setRetailerPersonaData={setRetailerPersonaData}
          setRetailerPersonaId={setRetailerPersonaId}
        />
      )}
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProviderWrapper };

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */
async function CreatePersonasForNewSageUser(
  fbUser: any, //todo
  formData: SageAuthFormValuesShape
) {
  try {
    const identities = await Identity_EnsureIdentityExistsForAuthLikeObject(
      fbUser
    );
    if (!identities?.identity_private)
      throw new Error('Failed to get or create identity');

    Identity_SubscribeToChanges(identities.identity_private);

    const personas = await Sage_Identity_CreatePersonasForIdentity(
      identities?.identity.docid,
      formData
    );

    if (!personas?.repairer_private)
      throw new Error('Failed to create repairer persona');
    console.log('New repairer persona created successfully');

    return personas;
  } catch (error) {
    console.error(error);
    return null;
  }
}
