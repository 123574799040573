import { FieldArray, Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '@rabbit/config/context';
import {
  CLAIM_CAUSE_OPTIONS,
  CLAIM_OUTCOME_OPTIONS,
  COVERAGE_OPTIONS,
  COVERAGE_OPTIONS_FATBIKES,
  FileStorageContext,
  getCompletedUploadsOfCategory,
  useManageFaults,
} from '@rabbit/bizproc/react';
import { SageFileUploader } from '../../upload-wrapper/SageFileUploader';
import {
  CompletedUploadShape,
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import {
  AssessmentPart,
  CaseFactsShape,
  CaseflowCaseTypes,
} from '@rabbit/bizproc/core';
import { FBD_Vendable, UserUploadedDocument } from '@rabbit/data/types';
import { TrashIcon } from '@heroicons/react/24/solid';
import { VeilKeys } from '@rabbit/mixmaster/veils';

interface FormValuesShape {
  assessment_parts: AssessmentPart[];
  holding_faults: string[];
  preliminary_assessment: string;
  final_assessment: string;
  claim_outcome: string;
  assessment_comment: string;
  assessment_attachment: UserUploadedDocument[];
  claim_cause: string;
}

const validationSchema = Yup.object().shape({
  assessment_parts: Yup.array(
    Yup.object({
      name: Yup.string(),
      manufacturer: Yup.string(),
      description: Yup.string(),
    })
  ),
  holding_faults: Yup.array(Yup.string()),
  preliminary_assessment: Yup.string(),
  final_assessment: Yup.string(),
  claim_outcome: Yup.string(),
  assessment_comment: Yup.string(),
  claim_cause: Yup.string(),
  assessment_attachment: Yup.array(Yup.object()),
});

export default function UpdateClaimAssessmentModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const { faultList } = useManageFaults();
  const [comment, setComment] = useState(false);
  const faultOptions = !faultList?.data
    ? []
    : faultList.data.map((fault) => ({
        id: fault.docid,
        value: fault.docid,
        label: fault.title,
        name: fault.title,
      }));
  const { config } = useContext(ConfigContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    isUpdating,
    updateHoldingWithFiles,
  } = useContext(FileStorageContext) || {};

  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    operatingPersonaSingleKey,
    caseId,
    caseFlowCase,
    alterCasePublicEmail,
    caseActors,
  } = useContext(CaseflowContext) || {};

  if (
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId ||
    !caseFlowCase ||
    !alterCasePublicEmail ||
    !caseActors
  )
    return <LoadingSpinner size={'xs'} />;

  const initialValues: FormValuesShape = {
    assessment_parts: caseFacts?.assessment_parts || [],
    holding_faults: caseFacts?.holding_faults || [],
    preliminary_assessment: caseFacts?.preliminary_assessment || '',
    final_assessment: caseFacts?.final_assessment || '',
    claim_outcome: caseFacts?.claim_outcome || '',
    claim_cause: caseFacts?.claim_cause || '',
    assessment_comment: caseFacts?.assessment_comment || '',
    assessment_attachment: caseFacts?.assessment_attachment || [],
  };

  const onSubmit = async (values: FormValuesShape) => {
    const {
      holding_faults,
      claim_outcome,
      claim_cause,
      assessment_comment,
      preliminary_assessment,
      final_assessment,
      assessment_parts,
      assessment_attachment,
    } = values;

    const { completed } = uploadQueueState ?? {};
    const attachment: CompletedUploadShape[] = getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.AssessmentAttachment
    );

    try {
      setIsSubmitting(true);

      const factsToAlter = {
        assessment_comment,
        holding_faults,
        preliminary_assessment,
        final_assessment,
        claim_outcome,
        claim_cause,
        assessment_attachment:
          values.assessment_attachment &&
          values.assessment_attachment.length > 0
            ? values.assessment_attachment
            : caseFacts?.assessment_attachment,
        assessment_parts,
      } as Partial<CaseFactsShape>;

      await alterCaseFacts(factsToAlter);
      attachment.length > 0 && moveCompletedUploadsToAttached?.(attachment);
      toast.success(t('general.claimUpdatedSuccessfully'));
      onClose();
    } catch (err) {
      setIsSubmitting(false);
      toast.error(t('message.failedToUpdateClaimDetails'));
      console.log(err);
    }
  };

  return (
    <Modal
      settings={{
        title: t('general.updateAssessment'),
        headerBackground: true,
        handleClose: () => onClose(),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[calc(100vh - 200px)] w-full max-w-[768px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values, setFieldValue }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <FieldArray name="assessment_parts">
              {({ push, remove }) => (
                <div className="flex flex-col gap-4">
                  {values.assessment_parts?.map((part, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-[1fr_1fr_1fr_40px] items-end gap-4"
                    >
                      {config.VENDABLES.PART_SEARCH.ENABLED && (
                        <Input
                          type="autocomplete-vendable-part"
                          name={`assessment_parts.${index}.vendableId`}
                          label={t('general.parts')}
                          settings={{
                            placeholder: t('general.parts'),
                            tenantLink: t(VeilKeys.TENANT_LINK),
                          }}
                          onChange={async (e) => {
                            const vendableId = e.id;
                            const vendable = await FBD_Vendable.get(vendableId);
                            if (vendable) {
                              void setFieldValue(
                                `assessment_parts.${index}.name`,
                                vendable.title
                              );
                              void setFieldValue(
                                `assessment_parts.${index}.manufacturer`,
                                vendable.brand
                              );
                              void setFieldValue(
                                `assessment_parts.${index}.id`,
                                vendable.mpn
                              );
                            }
                          }}
                        />
                      )}
                      <Input
                        type="text"
                        label={
                          config.VENDABLES.PART_SEARCH.ENABLED
                            ? ''
                            : t('general.parts')
                        }
                        name={`assessment_parts.${index}.name`}
                        settings={{
                          placeholder: t('general.parts'),
                        }}
                        className={
                          config.VENDABLES.PART_SEARCH.ENABLED ? 'hidden' : ''
                        }
                      />
                      <Input
                        type="text"
                        label={t('general.manufacturer')}
                        name={`assessment_parts.${index}.manufacturer`}
                        settings={{
                          placeholder: t('general.partManufacturer'),
                        }}
                      />
                      <Input
                        type="text"
                        label={t('general.description')}
                        name={`assessment_parts.${index}.description`}
                        settings={{
                          placeholder: t('general.partDescription'),
                        }}
                      />
                      <div className="grid h-[44px] grid-cols-2 items-center gap-2 pl-2">
                        <TrashIcon
                          className="h-8 w-8 cursor-pointer text-red-500"
                          onClick={() => remove(index)}
                        />
                      </div>
                    </div>
                  ))}
                  <Button
                    kind="outline"
                    type="button"
                    onClick={() =>
                      push({ name: '', manufacturer: '', description: '' })
                    }
                  >
                    {t('general.addPart')}
                  </Button>
                </div>
              )}
            </FieldArray>
            <Input
              type="select"
              label={t('general.faults')}
              name="holding_faults"
              settings={{
                options: faultOptions,
                id: 'fault',
                placeholder: t('general.pleaseSelectAFault'),
                isMulti: true,
              }}
            />
            <Input
              type="select"
              label={t('general.preliminaryAssessment')}
              name="preliminary_assessment"
              settings={{
                options:
                  config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES
                    ? COVERAGE_OPTIONS_FATBIKES
                    : COVERAGE_OPTIONS,
                placeholder: t('general.pleaseSelectAnAssessment'),
              }}
            />
            <Input
              type="select"
              label={t('general.finalAssessment')}
              name="final_assessment"
              settings={{
                options:
                  config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES
                    ? COVERAGE_OPTIONS_FATBIKES
                    : COVERAGE_OPTIONS,
                placeholder: t('general.pleaseSelectAnAssessment'),
              }}
            />
            <Input
              type="select"
              label={t('general.outcome')}
              name="claim_outcome"
              settings={{
                options: CLAIM_OUTCOME_OPTIONS,
                id: 'outcome',
                placeholder: t('general.pleaseSelectAnOutcome'),
              }}
            />
            <Input
              type="select"
              label={t('general.cause')}
              name="claim_cause"
              settings={{
                options: CLAIM_CAUSE_OPTIONS,
                placeholder: t('general.pleaseSelectACause'),
              }}
            />
            {!comment && !values.assessment_comment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setComment(true)}
                >
                  {t('general.additionalComment')}
                </Button>
              </div>
            )}
            {(comment || values.assessment_comment) && (
              <div>
                <Input
                  type="rich-text"
                  label={t('general.additionalComment')}
                  name="assessment_comment"
                  settings={{
                    id: 'assessment_comment',
                    placeholder: '',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <SageFileUploader
              label={t('general.additionalDocument')}
              identifiers={{
                category: UploadedFileCategories.AssessmentAttachment,
                docType: {
                  docid: caseId,
                  type: DocTypeShapeTypes.Case,
                },
                personaId: caseActors?.consumer ?? '',
              }}
              onUploadCompleted={(files) => {
                setFieldValue(
                  'assessment_attachment',
                  files.flatMap((file: CompletedUploadShape) =>
                    file.category ===
                      UploadedFileCategories.AssessmentAttachment &&
                    file?.uploadedFile
                      ? [file.uploadedFile]
                      : []
                  )
                );
              }}
              alterCaseFacts={alterCaseFacts}
              accepts={['image/*', '.pdf']}
              currentFiles={caseFacts?.assessment_attachment ?? []}
            />
            <div className="mt-8 flex gap-8">
              <Button
                kind="primary"
                type="submit"
                loading={isSubmitting}
                disabled={Object.keys(errors).length > 0}
              >
                {t('general.updateAssessment')}
              </Button>
              <Button kind="outline_red" onClick={() => onClose()}>
                {t('general.cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
