import {
  CardWrapperWithHeader,
  chartProducts,
  chartRegistrations,
  FilterValueShape,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  //ManufacturerHoldingWithVendableData,
  useGetHoldingList,
} from '@rabbit/bizproc/react';
import {
  formatDataChart,
  getRetailerById,
  ChartDataShape,
  getUserPermissions,
} from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext';
import TableRegistrations from '../components/molecules/TableRegistrations/TableRegistrations';
import { Navigate, useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import {
  DTHoldingProxy,
  Permissions,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  SELF_REGISTERED_VENDABLE,
  WarrantyStatus,
} from '@rabbit/data/types';
import { BL_Warranty, Permissions2string } from '@rabbit/bizproc/core';
import { ConfigContext } from '@rabbit/config/context';

export type TenantType = 'carTenants' | 'tableTenants' | 'defaultTenants';

interface ChartCountItemShape {
  id: string;
  count: number;
  label: string;
}

// Note: several parts of useGetHoldingList are commented out, as they handle pagination.
// For now all the data processing is being done here rather than in the back end.
// This will likely change in the future. - DC

export function RegistrationsView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, userContext);
  const contextValues = useContext(UserContext);
  const isPremiumTenant =
    contextValues?.accessToken?.['W:' + tenantLink] === 'S';

  const { accessToken, repairerPersonaId, retailerPersonaId } =
    userContext ?? ({} as any);

  const { config } = useContext(ConfigContext);

  // sorry about this one - DC
  // quick hack for preventing fatbikes repairers from accessing the registrations page
  // todo: create a nice tidy way to handle checking allowed routes and redirecting away from restricted ones
  const permissionsFromRootRepairer =
    accessToken[
      PersonaTypeSingleLetter.Repairer + PersonaIdTypeSplitter + t('tenantLink')
    ];

  if (
    permissionsFromRootRepairer &&
    !permissionsFromRootRepairer.includes(
      Permissions2string([Permissions.Owner])
    ) &&
    config.NAVIGATION.SAGE.DEFAULT_PARTNER_PATH &&
    config.NAVIGATION.SAGE.DEFAULT_PARTNER_PATH !==
      SAGE_ROUTE_NAME.REGISTRATIONS &&
    config.NAVIGATION.RESTRICTED_PATHS.includes(SAGE_ROUTE_NAME.REGISTRATIONS)
  ) {
    navigate(config.NAVIGATION.SAGE.DEFAULT_PARTNER_PATH);
  }

  // TODO: In the future it would be nice to get this directly from the context - DC
  const activePersonaIds = {
    repairer: repairerPersonaId,
    retailer: retailerPersonaId,
  };

  //Set the filters default date range to this month -VP
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const beginDate = new Date(currentYear, currentMonth, 1);
  const endDate = today;

  const [filters, setFilters] = useState<FilterValueShape>(
    t('tenantLink') === 'WARRANTYIRELAND'
      ? {
          dateType: 'This month',
          beginDate: beginDate,
          endDate: endDate,
        }
      : {}
  );

  let tenantType: TenantType = 'defaultTenants';
  //const [pageSize, setPageSize] = useState(500);
  const {
    holdingList,
    // getNextPage,
    //totalHoldingCount,
    isLoading,
  } = useGetHoldingList(
    //pageSize,
    t('tenantLink'),
    accessToken,
    activePersonaIds,
    filters
  );

  const [chartMonthRegistrationsData, setChartMonthRegistrationsData] =
    useState<any[]>([]);
  const [chartRetailersData, setChartRetailersData] = useState<
    ChartDataShape[]
  >([]);

  if (
    t('tenantLink') === 'WARRANTYIRELAND' ||
    t('tenantLink') === 'PINNACLEWARRANTIES'
  )
    tenantType = 'carTenants';
  if (t('tenantLink') === 'NUCOVER') tenantType = 'tableTenants';

  const onChangeFilters = (value: FilterValueShape) => {
    setFilters(value);
  };

  // Format the results
  useEffect(() => {
    const setupCarMonthlyRegistrations = (holdings: DTHoldingProxy[]) => {
      const values: any = {};
      holdings.forEach((holding) => {
        const latestWarranty = BL_Warranty.getLatestWarranty(
          holding.warranties
        );
        //if (latestWarranty?.status === WarrantyStatus.VOIDED) return;
        if (latestWarranty?.status === WarrantyStatus.VOIDED) {
          values['Voided'] = values['Voided'] || 0;
          values['Voided'] = values['Voided'] + 1;
        } else {
          const name = latestWarranty?.templateTitle || '';
          values[name] = values[name] || 0;
          values[name] = values[name] + 1;
        }
      });
      setChartMonthRegistrationsData(
        formatDataChart(
          Object.keys(values).map((name, index) => ({
            id: index,
            count: values[name],
            label: name,
          })),
          'series',
          'Registrations per plan',
          5
        )
      );
    };

    const setupStonesMonthlyRegistrations = (holdings: any[]) => {
      const values: any = {};
      holdings.forEach((holding) => {
        const name = holding.self_registration.srvInfo.productInfo.typeofStone;
        values[name] = values[name] || 0;
        values[name] = values[name] + 1;
      });
      setChartMonthRegistrationsData(
        formatDataChart(
          Object.keys(values).map((name, index) => ({
            id: index,
            count: values[name],
            label: name,
          })),
          'series',
          'Regisrations',
          5
        )
      );
    };

    const setupChartMontlyRegistrations = (
      // todo: type - see above
      // holdings: ManufacturerHoldingWithVendableData[]
      holdings: any[]
    ) => {
      const monthProductRegistrations: {
        id: any;
        count: number;
        label: any;
      }[] = [];
      const lastMonthTimestamp = Date.now() - 30 * 24 * 60 * 60 * 1000;

      //Filter out SRV holdings
      const filteredHoldings = holdings.filter(
        (holding) => holding.vendable !== SELF_REGISTERED_VENDABLE
      );

      filteredHoldings.forEach((holding, i) => {
        if (holding.register_time >= lastMonthTimestamp) {
          const existingItem = monthProductRegistrations.find(
            (item) => item.label === holding.title
          );
          if (existingItem) {
            existingItem.count++;
          } else {
            monthProductRegistrations.push({
              id: holding.vendable,
              count: 1,
              label: holding.title ?? 'No title',
            });
          }
        }
      });

      const sortedMonthRegistrations = monthProductRegistrations.sort(
        (a, b) => b.count - a.count
      );
      setChartMonthRegistrationsData(
        formatDataChart(sortedMonthRegistrations, 'series', 'Regisrations', 5)
      );
    };

    // refactor this to count all the ids, and then just fetch labels for the top 5
    const setupChartRetailerRegistrations = async (
      // todo - type, see above
      // holdings: ManufacturerHoldingWithVendableData[]
      holdings: any[]
    ) => {
      const retailerProductRegistrations: ChartCountItemShape[] = [];

      // For retailers that are not in the database and have been submitted by users (purchase_location_other)
      const UNLISTED_RETAILER_ID = '1';

      for (const holding of holdings) {
        // No purchase location handling

        // Database retailer handling
        const existingItem = retailerProductRegistrations.find(
          (item) => item.id === holding.purchase_location
        );
        if (existingItem) {
          existingItem.count++;
        } else {
          retailerProductRegistrations.push({
            id: holding.purchase_location,
            count: 1,
            label: 'No title',
          });

          // Self-registered retailer handling
          const unlistedRetailerItem = retailerProductRegistrations.find(
            (item) => item.id === UNLISTED_RETAILER_ID
          );
          if (holding.purchase_location_other) {
            if (unlistedRetailerItem) {
              unlistedRetailerItem.count++;
            } else {
              retailerProductRegistrations.push({
                id: UNLISTED_RETAILER_ID,
                count: 1,
                label: 'Unlisted',
              });
            }
          }
        }
        if (holding.shopifyLinks) {
          const shopifyExists = retailerProductRegistrations.find(
            (item) => item.id === 'shopify'
          );
          if (shopifyExists) {
            shopifyExists.count++;
          } else {
            retailerProductRegistrations.push({
              id: 'shopify',
              count: 1,
              label: 'Shopify',
            });
          }
        }
      }
      const chartData = await parseRawRetailerCounts(
        retailerProductRegistrations,
        5
      );
      setChartRetailersData(chartData);
    };

    if (!isLoading && holdingList.data) {
      switch (tenantType) {
        case 'carTenants':
          setupCarMonthlyRegistrations(holdingList?.data);
          break;
        case 'tableTenants':
          setupStonesMonthlyRegistrations(holdingList?.data);
          break;
        default:
          setupChartMontlyRegistrations(holdingList?.data);
      }
      void setupChartRetailerRegistrations(holdingList?.data);
    }
  }, [holdingList.data, isLoading]);

  if (
    !userPermissions?.includes(Permissions.RegistrationsEdit) &&
    !userPermissions?.includes(Permissions.RegistrationsView) &&
    !userPermissions?.includes(Permissions.Owner)
  )
    return <Navigate to={SAGE_ROUTE_NAME.MANAGE_ACCOUNT} />;

  if (!activePersonaIds.repairer && !activePersonaIds.retailer)
    return <LoadingSpinner size="sm" />;

  return (
    <>
      {tenantType === 'carTenants' || tenantType === 'tableTenants' ? (
        <div className="relative">
          <CardWrapperWithHeader
            title={
              filters.dateType === 'This month'
                ? "This month's registrations"
                : filters.dateType === 'Last month'
                ? "Last month's registrations"
                : filters.dateType === '' || filters.dateType === undefined
                ? 'Total registrations'
                : 'Registrations within selected range'
            }
            headerRight={
              holdingList?.data?.length && (
                <div className="text-xl font-bold">
                  Total: {holdingList?.data?.length}
                </div>
              )
            }
          >
            <Chart
              options={chartProducts.options}
              series={chartMonthRegistrationsData}
              type="bar"
              height={'250px'}
            />
          </CardWrapperWithHeader>
        </div>
      ) : (
        <div className="mb-5 grid grid-cols-2 gap-5">
          <CardWrapperWithHeader title={t('message.productRegistrations')}>
            <Chart
              options={chartProducts.options}
              series={chartMonthRegistrationsData}
              type="bar"
              height={'250px'}
            />
          </CardWrapperWithHeader>
          <CardWrapperWithHeader title={t('message.registrationByChannel')}>
            <Chart
              options={chartRegistrations.options}
              series={chartRetailersData}
              type="bar"
              height={'250px'}
            />
          </CardWrapperWithHeader>
        </div>
      )}
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <TableRegistrations
          isLoading={isLoading}
          tenantType={tenantType}
          holdingList={holdingList}
          filters={filters}
          onFilter={onChangeFilters}
          isRegularTenant={!isPremiumTenant}
        />
      </div>
    </>
  );
}

export default RegistrationsView;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const parseRawRetailerCounts = async (
  rawData: ChartCountItemShape[],
  limit: number
) => {
  const sortedData = rawData.sort((a, b) => b.count - a.count);

  // Get retailer labels for the top limit + 3 retailers (to avoid potential missing labels)
  const topRetailers = sortedData.slice(0, limit + 3);
  const topRetailersIds = topRetailers.map((retailer) => retailer.id);
  const topRetailersLabels = await Promise.all(
    topRetailersIds.map((id) => getRetailerById(id))
  );

  // Replace the labels on the top retailers with the fetched ones
  topRetailers.forEach((retailer, i) => {
    const retailerLabel = topRetailersLabels[i];
    if (retailerLabel) {
      retailer.label =
        typeof retailerLabel === 'object' &&
        typeof retailerLabel?.name === 'string'
          ? retailerLabel?.name
          : 'No title';
    }
  });

  // Filter out the ones with no titles
  const filteredTopRetailers: ChartCountItemShape[] = topRetailers.filter(
    (retailer) => retailer.label !== 'No title'
  );

  return formatDataChart(
    filteredTopRetailers,
    'series',
    'Registrations',
    limit
  );
};
