import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '@rabbit/config/context';
import {
  CLAIM_STATUS_OPTIONS_FATBIKES,
  CLAIM_STATUS_OPTIONS_LITE,
} from '@rabbit/bizproc/react';
import { CaseFlowCaseStations, CaseflowCaseTypes } from '@rabbit/bizproc/core';
import { ClaimCaseEmailNotification } from '@rabbit/sage/utils/ClaimEmailNotification';
import { CaseFlowFacts } from '@rabbit/data/types';

interface FormValuesShape {
  status: string;
  comment_to_customer?: string;
}

const validationSchema = Yup.object().shape({
  status: Yup.string().trim().required('Please select a status'),
  comment_to_customer: Yup.string()
    .test('remove-html-tags', 'please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    })
    .trim(),
});

export default function ClaimStatusModal({ onClose }: { onClose: () => void }) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    operatingPersonaSingleKey,
    caseId,
    caseFlowCase,
    alterCasePublicEmail,
    caseActors,
  } = useContext(CaseflowContext) || {};

  if (
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId ||
    !caseFlowCase ||
    !alterCasePublicEmail ||
    !caseActors
  )
    return <LoadingSpinner size={'xs'} />;

  const {
    holding_faults,
    consumer_issue_description,
    consumer_issue_type,
    consumer_issue_type_ref,
    preliminary_assessment,
    final_assessment,
  } = caseFacts;

  const initialValues = {
    status: caseFlowCase?.GetCaseState() || '',
  };

  const onSubmit = async (values: FormValuesShape) => {
    const { status, comment_to_customer } = values;
    let factUpdates: CaseFlowFacts = {
      holding_faults,
      consumer_issue_description,
      consumer_issue_type,
      consumer_issue_type_ref,
      preliminary_assessment,
      final_assessment,
    };

    setIsSubmitting(true);
    // update case actions
    try {
      if (status && status !== caseFlowCase.GetCaseState()) {
        // If the status is moved to preliminary assessment, we need to reset the repairer and the invoices
        if (
          status === CaseFlowCaseStations.PRELIMINARY_ASSESSMENT &&
          config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES
        ) {
          executeAction &&
            (await executeAction('assign_case', {
              delegate_repairer_id: 'Unassigned',
              delegate_repairer_name: 'Unassigned',
            }));
          factUpdates = {
            ...factUpdates,
            external_repair_invoices: [],
            comment_to_customer: comment_to_customer,
          };
        }
        executeAction && (await executeAction(`move_to_${status}`));
      }

      alterCaseFacts && (await alterCaseFacts(factUpdates));
      await ClaimCaseEmailNotification({
        status,
        caseFlowCase,
        appInfo,
        alterCasePublicEmail,
        t,
      });
      toast.success(t('Claim updated successfully.'));
      onClose();
    } catch (err) {
      setIsSubmitting(false);
      toast.error(t('message.failedToUpdateClaimDetails'));
      console.log(err);
    }
  };

  return (
    <Modal
      settings={{
        title: t('general.claimStatus'),
        headerBackground: true,
        handleClose: () => onClose(),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="select"
              label={`${t('general.claimStatus')}*`}
              name="status"
              settings={{
                options:
                  config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES
                    ? CLAIM_STATUS_OPTIONS_FATBIKES
                    : CLAIM_STATUS_OPTIONS_LITE,
                id: 'status',
                placeholder: t('Please select an option'),
                hint: '*required',
              }}
            />
            <Input
              type="rich-text"
              label={t('general.commentToCustomer')}
              name="comment_to_customer"
              settings={{
                id: 'comment_to_customer',
              }}
            />
            <div className="mt-8 flex gap-8">
              <Button
                kind="primary"
                type="submit"
                loading={isSubmitting}
                disabled={errors.status !== undefined}
              >
                {t('general.updateStatus')}
              </Button>
              <Button kind="outline_red" onClick={() => onClose()}>
                {t('general.cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
